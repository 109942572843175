export default {
  name: 'PromoMessage',
  props: {
    order: {
      required: true,
      type: Object
    },
    currency: {
      required: true,
      type: Object
    }
  }
}
