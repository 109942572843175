import PaymentsTypes from '@/components/scripts/PageFooter/FooterNavigation/PaymentsTypes/index.vue'
import TrustpilotWidget from '@/components/scripts/TrustpilotWidget/index.vue'
import ConfirmDeleteModal from './ConfirmDeleteModal/index.vue'
import DeliveryInfoModal from './DeliveryInfoModal/index.vue'
import ReturnsInfoModal from './ReturnsInfoModal/index.vue'
import TermsInfoModal from './TermsInfoModal/index.vue'
import Express from './Express/index.vue'
import ReglazeMessage from './ReglazeMessage/index.vue'
import PromoMessage from './PromoMessage/index.vue'
import Item from './Item/index.vue'
import Prescription from './Prescription/index.vue'
import TwoForOne from './TwoForOne/index.vue'
import Accessories from './Item/Accessories/index.vue'
import ContactLenses from './Item/ContactLenses/index.vue'
import Googles from './Item/Googles/index.vue'
import Reglaze from './Item/Reglaze/index.vue'
import SavedItem from './Saved/Item/index.vue'
import SavedPrescription from './Saved/Prescription/index.vue'
import SavedAccessories from './Saved/Item/Accessories/index.vue'
import SavedContactLenses from './Saved/Item/ContactLenses/index.vue'
import SavedGoogles from './Saved/Item/Googles/index.vue'
import SavedReglaze from './Saved/Item/Reglaze/index.vue'
import { BigNumber } from 'bignumber.js'
import config from '@/../config'

import { mapState } from 'vuex'
import { reverseRouteName } from '@/../config/helper'
import $ from 'jquery'

export default {
  name: 'SSv4Basket',
  components: {
    PaymentsTypes,
    TrustpilotWidget,
    ConfirmDeleteModal,
    DeliveryInfoModal,
    ReturnsInfoModal,
    TermsInfoModal,
    Item,
    Accessories,
    ContactLenses,
    Googles,
    Reglaze,
    Prescription,
    SavedItem,
    SavedPrescription,
    SavedAccessories,
    SavedContactLenses,
    SavedGoogles,
    SavedReglaze,
    Express,
    TwoForOne,
    ReglazeMessage,
    PromoMessage
  },
  data () {
    return {
      delOrderedItemID: null,
      delSavedItemID: null,
      hasDiscount: false,
      promoCode: undefined,
      hasExpress: false,
      clickedItem: {},
      itemMovedTo: '',
      showNoItemsSaved: false,
      showNoItemsBasket: true,
      order_id: false,
      logEntry: {},
      isOrderLoaded: false,
      isPageReady: false,
      freeShippingLimit: 75
    }
  },
  computed: {
    ...mapState({
      order: (state) => ({...state.ssv4Order}),
      saved: (state) => ({...state.ssv4SavedItems}),
      user: (state) => ({...state.ssv4User}),
      meta: (state) => ({
        ...state.formMeta['basket']
      }),
      basketLoading: (state) => state.basketLoading,
      loading: (state) => state.loading,
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]}),
      wishlist: (state) => state.ssv4Wishlist,
      exchange: (state) => state.currency.exchange
    }),
    priceForFreeDelivery () {
      const total = new BigNumber(this.order.final_total)
      const shipping = new BigNumber(this.order.shipping_cost)
      const limit = new BigNumber(this.calculatePrice(this.freeShippingLimit, false, false, false))
      return this.getPrice(limit.minus(total).plus(shipping).toNumber())
    },
    items () {
      const {...data} = this.order
      if (!data || !data.items) {
        return []
      }
      return data.items.filter(item => !item.buy_later) || []
    },
    totalItems () {
      return this.order && this.order.items && this.order.items.reduce((acc, item) => {
        return acc + item.quantity
      }, 0) || false
    },
    laterItems () {
      return this.saved || []
    },
    reglazeItems () {
      return this.order && this.order.items && this.order.items.reduce((acc, item) => {
        return acc + (item.type === 'reglaze' ? 1 : 0)
      }, 0) || false
    },
    loggedIN () {
      this.log('user')(this.user)
      return !!(this.user && this.user.user_number && this.user.default_profile_number)
    },
    hasProducts: function () {
      return this.totalItems > 0
    },
    hasSavedOrProduct () {
      return this.hasProducts || this.somethingSaved
    },
    basketOrderCount () {
      return this.order && this.order.items && this.order.items.length || 0
    },
    basketAmount: function () {
      return this.order && this.order.items && this.order.items.length || 0
    },
    savedAmount: function () {
      return Object.values(this.saved).length || 0
    },
    somethingSaved: function () {
      return this.savedAmount > 0
    }
  },
  methods: {
    convertPrice: function (price) {
      try {
        return parseFloat(this.exchange(this.vat(price).price)).toFixed(2)
      } catch (e) {
        return parseFloat(this.exchange(price)).toFixed(2)
      }
    },
    getPrice (price) {
      if (!this.currency) {
        return 0.0
      }
      const currency = Object.assign({}, this.currency)
      let val = new BigNumber(price)
      BigNumber.config(currency.to_greater && {ROUNDING_MODE: BigNumber.ROUND_CEIL} || {ROUNDING_MODE: BigNumber.ROUND_HALF_UP})
      val = val.shiftedBy(currency.precision_digit || 0)
      val = val.decimalPlaces(0)
      return val.shiftedBy(-currency.precision_digit || 0).toFixed(currency.precision_digit > 0 ? currency.precision_digit : 2)
    },
    formatNumber (num) {
      let val = new BigNumber(num)
      BigNumber.config({
        ROUNDING_MODE: BigNumber.ROUND_HALF_UP
      })
      val = val.shiftedBy(2)
      val = val.decimalPlaces(0)
      return val.shiftedBy(-2).toFixed(2)
    },
    getPriceWithVat (price) {
      const vat = new BigNumber(this.order && this.order.vat_perc || 1)
      return this.getPrice(this.calculatePrice(vat.multipliedBy(price).toNumber(), false, false, false))
    },
    getExchangePrice (price) {
      return this.getPrice(this.calculatePrice(price, false, false, false))
    },
    getTotal () {
      return this.getPrice(this.order.final_total)
    },
    assignItems (field, newItems) {
      newItems.map(item => {
        const index = this[field].findIndex(jtem => jtem.ordered_item_number == item.ordered_item_number)
        if (index >= 0) {
          Object.keys(item).map(key => {
            this[field][index][key] = item[key]
          })
          return
        }

        this[field].push(item)
      })

      this[field].map((item, index) => {
        const jndex = newItems.findIndex(jtem => jtem.ordered_item_number == item.ordered_item_number)
        if (jndex < 0) {
          this[field].splice(index, 1)
        }
      })
    },
    checkout: function () {
      this.$router.push(this.$link('/checkout/') + this.order.hash + '/?sspay=true')
    },
    changeQtyItem (itemOrderedID, e) {
      let data = {
        'quantity': e.target.value,
        'ordered_item_number': itemOrderedID
      }
      this.$store.dispatch('setQtyItemSSv4', data)
    },
    removeItem (itemID) {
      this.$store.dispatch('removeItemFromCartSSv4', itemID)
    },
    removeSaved (itemID) {
      this.$store.dispatch('removeFromSaveCartSSv4', itemID)
    },
    reverseRouteName: function (str, defaultName = 'route-name') {
      if (str && str.length > 0) {
        return reverseRouteName(str)
      }
      return defaultName
    },
    addToSave: function (itemID) {
      this.$store.dispatch('addToSaveCartSSv4', itemID)
      this.showMovedBlock(itemID, 'later')
    },
    moveFromSave: function (itemID) {
      this.$store.dispatch('moveFromSaveCartSSv4', itemID)
      this.showMovedBlock(itemID, 'basket')
    },
    submitDiscount (e) {
      e.preventDefault()
      this.$store.dispatch('onSubmitPromoCodeCartSSv4', this.promoCode)
    },
    toggleDiscountBox (e) {
      e.preventDefault()
      this.hasDiscount = !this.hasDiscount
    },
    getItemPrice (item) {
      return this.getPrice(item.total)
    },
    getItemSave (item) {
      return +item.count_2_for_1 ? this.getPrice(item.selling_price - item.total) : 0.0
    },
    showMovedBlock (id, to) {
      this.clickedItem = {}
      if (id) {
        if (to === 'basket') {
          const saved = Object.values(this.laterItems)
          this.log('Move', saved)
          this.clickedItem = saved.filter(
            item => item.id === id
          )[0]
        } else if (to === 'later') {
          this.clickedItem = this.items.filter(
            item => item.id === id
          )[0]
        }
        this.itemMovedTo = to
        this.showNoItemsSaved = true
        this.showNoItemsBasket = false
      }
    },
    fetchOrder () {
      this.order_id = this.$route.query.order_id || false
      return this.$store.dispatch('getSSv4Basket', {order_id: this.order_id})
        .then(data => {
          this.isOrderLoaded = true
          return data
        })
    },
    fetchSaved () {
      return this.$store.dispatch('loadSavedItemsCartSSv4')
    },
    fetchCurrencies () {
      return this.$store.dispatch('loadCurrencyCartSSv4')
    },
    fetchUser () {
      return this.$store.dispatch('checkLoginCartSSv4')
    },
    log (type) {
      if (!this.logEntry[type]) {
        let count = 0
        this.logEntry[type] = data => {
          if (config.env.NODE_ENV !== 'production') {
            console.debug(type + ' #' + ++count, JSON.parse(JSON.stringify(data)))
          }
        }
      }
      return this.logEntry[type]
    },
    removeOrderedItem (itemID) {
      this.delOrderedItemID = itemID
      this.delSavedItemID = null
      this.removeItem(itemID)
    },
    removeSavedItem (itemID) {
      this.delSavedItemID = itemID
      this.delOrderedItemID = null
      this.removeSaved(itemID)
    },
    isReady () {
      return this.isOrderLoaded
    }
  },
  mounted () {
    /*
    if (!this.checkSSRmounting()) {
      return
    }
    */

    $(window).on('beforeunload', function (e) {
      this.itemMovedTo = ''
      this.showNoItemsSaved = false
    })

    this.fetchSSv4Menu()
      .then(() => this.fetchCurrencies())
      .then(() => this.fetchOrder())
      .then(() => this.fetchUser())
      .then(() => this.fetchSaved())
      .then(() => {
        this.isPageReady = true
      })
  },
  watch: {
    saved (data) {
      if (this.isMounted) {
        this.log('Saved')(data)
      }
    },
    currency (data) {
      if (!this.isPageReady || !data.number || !this.order || !this.order.id) {
        return
      }
      this.log('Currency')({
        currency: data,
        order: this.order
      })
      this.$store.dispatch('setCurrencyCartSSv4', {id: data.number, orderID: this.order.hash})
    }
  },
  asyncData ({store, route}) {
    const order_id = route.query.order_id || false
    return store.dispatch(store.state.isMobile ? 'loadSSv4MobileMenu' : 'loadSSv4Menu')
      .then(() => store.dispatch('loadCurrencyCartSSv4'))
      .then(() => store.dispatch('getSSv4Basket', {order_id}))
      .then(() => store.dispatch('checkLoginCartSSv4'))
      .then(() => store.dispatch('loadSavedItemsCartSSv4'))
  }
}
