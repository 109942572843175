import { mapState } from 'vuex'

export default {
  name: 'SavedItem',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    })
  },
  methods: {
    getExchangePrice (price) {
      return this.$parent.getExchangePrice(price)
    },
    getPriceWithVat (price) {
      return this.$parent.getPriceWithVat(price)
    }
  }
}
