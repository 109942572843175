import { mapState } from 'vuex'

export default {
  name: 'Prescription',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    }),
    isPdMira () {
      return ('' + this.item.prescription.PD).trim() === '0'
    },
    isPdCheck () {
      return (['charity', 'return']).indexOf(('' + this.item.prescription.info.pdcheckType).trim()) >= 0
    }
  },
  methods: {
    getExchangePrice (price) {
      return this.$parent.getExchangePrice(price)
    },
    getPrice (price) {
      return this.$parent.getPrice(price)
    }
  }
}
