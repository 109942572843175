import { mapState } from 'vuex'

export default {
  name: 'SavedPrescription',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState({
      currency: (state) => ({...state.currency.allCurrency[state.currency.selected]})
    }),
    isPdMira () {
      return !!this.item.pdapp
    },
    isPdCheck () {
      return !!this.item.pdcheck
    }
  },
  methods: {
    getExchangePrice (price) {
      return this.$parent.getExchangePrice(price)
    },
    getPrice (price) {
      return this.$parent.getPrice(price)
    }
  }
}
